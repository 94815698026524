@import "libs/styles.scss";

.icon-box {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 1em;
  height: 1em;
}

.icon-box svg {
  width: 100%;
  height: 100%;
}

body #head {
  position: relative;
}

body #head .nav {
  @media (min-width: 993px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: static;
  }

  @media (max-width: 992px) {
    padding-bottom: 1px !important;
  }
}

body #head .nav ul {
  margin-right: 15px;
  margin-block: 16px;

  @media (min-width: 1213px) {
    margin-right: 30px;
  }
}

.social-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  @media (max-width: 992px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.social-icons__item + .social-icons__item {
  margin-left: 8px;
}

.social-icons__item .icon-box {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 5px;
  font-size: 26px;
  fill: #fff;
}

.megamenu-toggler {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  border: none;
  font-size: 30px;
  color: #fff;
  margin-left: 12px;

  @media (min-width: 1213px) {
    margin-left: 30px;
  }

  &:focus {
    outline: none;
  }
}

.megamenu-toggler .icon {
  width: 33px;
}

.megamenu-toggler:not(.megamenu-toggler--open) .icon-cross,
.megamenu-toggler--open .icon-bars {
  display: none;
}

.megamenu-toggler__label {
  margin-left: 10px;
  font-size: .5em;
  text-transform: uppercase;
}

.lang-switch {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  @media (max-width: 992px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media (min-width: 993px) {
    margin-left: 12px;
  }

  @media (min-width: 1213px) {
    margin-left: 20px;
  }
}

.lang-switch__item {
  border: 1px solid #fff;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 26px;
  height: 26px;
  opacity: .5;
}

.lang-switch__item--active {
  opacity: 1;
}

.lang-switch__item + .lang-switch__item {
  margin-left: 8px;
}

.lang-switch__item img {
  width: 100%;
  height: 100%;
}

.megamenu {
  background-color: #000;
  color: #fff;
  font-size: 15px;

  @media (min-width: 993px) {
    background-image: url('../images/megamenu-bg.jpg');
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 1000;
    top: 100%;
    right: 0;
    left: 0;
    display: none;
    -webkit-box-shadow: 0 10px 10px rgba(#000, .25);
    box-shadow: 0 10px 10px rgba(#000, .25);
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.megamenu--open {
  display: block;
}

.megamenu a {
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    color: #fff;
  }
}

.megamenu p {
  margin-bottom: 25px;
}

.megamenu__products {
  @media (min-width: 1213px) {
    padding-right: 50px;
  }
}

.megamenu__heading {
  display: block;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 20px;

  @media (min-width: 993px) {
    margin-top: 0;
    margin-bottom: 30px;
    margin-left: 0;
  }

  @media (min-width: 1213px) {
    font-size: 24px;
  }

  @media (min-width: 1280px) {
    font-size: 27px;
  }
}

.megamenu__product {
  position: relative;
  display: block;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;

  @media (min-width: 1280px) {
    font-size: 20px;
  }

  &:hover {
    color: #fff;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    bottom: 0;
    left: 0;
    background: -webkit-gradient(linear, left bottom, left top, from(#000), to(rgba(255, 255, 255, 0)));
    background: -o-linear-gradient(bottom, #000, rgba(255, 255, 255, 0));
    background: linear-gradient(0deg, #000, rgba(255, 255, 255, 0));
  }
}

.megamenu__product + .megamenu__product {
  margin-top: 20px;
}

.megamenu__product__image {
  width: 100%;
  height: auto;
}

.megamenu__product__overlay {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 20px;
}

.megamenu__product__arrow {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 26px;
  flex: 0 0 26px;
  max-width: 26px;
  height: 26px;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  border-radius: 50%;
  border: 1px solid rgba(#fff, .5);
  margin-left: auto;
  -webkit-transition: background-color .5s, color .5s;
  -o-transition: background-color .5s, color .5s;
  transition: background-color .5s, color .5s;
}

.megamenu__product:hover .megamenu__product__arrow {
  background-color: #fff;
  color: #000;
}

.megamenu__contact__label {
  display: inline-block;

  @media (min-width: 1213px) {
    width: 70px;
  }
}

body #head .nav .megamenu__items {
  @media (min-width: 993px) {
    text-align: left;
    margin: 0;

    li {
      display: block;
      padding-left: 0 !important;
      line-height: 1.5;
      margin-bottom: 10px;

      a {
        border-bottom-color: transparent;
      }
    }
  }
}

.megamenu-toggler,
.megamenu__products,
.megamenu__contact {
  @media (max-width: 992px) {
    display: none;
  }
}

.megamenu {
  @media (max-width: 992px) {
    .row {
      margin-right: 0;
      margin-left: 0;
    }

    .container-fluid,
    div[class^="col"] {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.gallery {
  margin-bottom: -30px;
}

.gallery__item {
  display: block;
  position: relative;
  margin-bottom: 30px;

  span {
    width: 100%;
    height: 100%;
  }
}
